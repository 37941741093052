<template>
    <div>
        <div class="page-title">
            <span>报告补充</span>
        </div>

        <div v-if="detail.id">
            <div class="detail-item">

                <div class="member-container mb-4">
                    <table class="member-info-table at-table">
                        <tr>
                            <td rowspan="4" width="140px">
                                <div class="p-image-box" @click="editPhoto">
                                    <div class="pit p-image"
                                         :style="`background-image:url(${detail.photo || detail.report.photo})`"></div>
                                    <div class="sc">
                                        <a-icon type="upload"/>
                                    </div>
                                </div>
                            </td>
                            <th colspan="2">{{ detail.member_image.nickname || detail.ast_member.mark_name || detail.ast_member.name }}</th>
                            <th>性别</th>
                            <td>{{ detail.report.gender }}</td>
                            <th>年龄</th>
                            <td>
                                <modal-edit title="年龄" unit="年龄"
                                            :value=" detail.report.age "
                                            @save=" value => saveExtraInfo(['age',value])">
                                    {{ detail.report.age }}
                                    <a-icon type="edit"></a-icon>
                                </modal-edit>
                            </td>
                        </tr>
                        <tr>
                            <th>职业</th>
                            <td>
                                <a @click="activeJobsModal">
                                    <span>{{ detail.member_image.jobs || '未知' }} </span>
                                    <a-icon type="edit"></a-icon>
                                </a>
                            </td>
                            <th>用户ID</th>
                            <td>{{ detail.member_id }}</td>
                            <th>纸质报告ID</th>
                            <td>{{ detail.id }}</td>
                        </tr>
                        <tr>
                            <th>身高</th>
                            <td>
                                <modal-edit title="身高" unit="身高"
                                            :value=" detail.report.height "
                                            @save=" value => saveExtraInfo(['height',value])">
                                    {{ detail.report.height }}cm
                                    <a-icon type="edit"></a-icon>
                                </modal-edit>
                            </td>
                            <th>体重</th>
                            <td>
                                <modal-edit title="体重" unit="体重"
                                            :value=" detail.report.weight "
                                            @save=" value => saveExtraInfo(['weight',value])">
                                    {{ detail.report.weight }}kg
                                    <a-icon type="edit"></a-icon>
                                </modal-edit>
                            </td>
                        </tr>
                        <tr>
                            <th>撰稿人</th>
                            <td>
                                <modal-edit title="修改撰稿人"
                                            :value="detail.author"
                                            unit="撰稿人"
                                            @save="saveAuthorInfo"
                                            placeholder="">
                                    <span>{{ detail.author }} </span>
                                    <a-icon type="edit"></a-icon>
                                </modal-edit>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>

            <life-photo class="detail-item" @change="saveExtraInfo" :detail="detail"/>

            <detect-info class="detail-item np" :read-only="!!detail.print_paid_status" @change="saveExtraInfo"
                         :detect-options="detectOptions"/>

            <ex-image class="detail-item" :read-only="!!detail.print_paid_status" @change="saveExtraInfo"
                      :detail="detail"/>

        </div>

        <at-modal title="用户职业填写" v-model="showJobModal" @ok="syncImageInfo">
            <at-input v-model="jobs" class="full-with" placeholder="请输入职业" highlight unit="职业"/>
        </at-modal>

        <div class="preview-pdf" v-if="$app.id === 15 ">
            <a @click.stop="openPdf">PDF报告预览</a>
        </div>

        <at-modal :footer="null" title="修改头像" v-model="showHeadModal">

            <upload-image :done="headUploadDone"/>

            <div>请上传2M以下，宽高1：1的正方形照片</div>

        </at-modal>

    </div>
</template>

<script>
import api from "@/repo/api";
import LifePhoto from "@/views/AST/Paper/Com/LifePhoto.vue";
import DetectInfo from "@/views/AST/Paper/Com/DetectInfo.vue";
import AtInput from "@/components/AtInput.vue";
import ExImage from "@/views/AST/Paper/Com/ExImage.vue";
import AtModal from "@/components/AtModal.vue";
import auth from "@/auth";
import ModalEdit from "@/components/ModalEdit.vue";
import UploadImage from "@/components/UploadImage.vue";

export default {
    name: "Detail",
    components: {UploadImage, ModalEdit, AtModal, ExImage, AtInput, DetectInfo, LifePhoto},
    data() {
        return {
            routeMatch: '/ast/paper/orders',
            detailId: '',
            showJobModal: false,
            detail: {},
            detectOptions: {},
            showAuthorModal: false,
            showHeadModal: false,
            author: "",
            jobs: "",
        }
    },
    computed: {
        pdfUrl() {
            let baseUrl = 'https://api.yanchuang.ycsd.work/api/collocation/ast-detect/pdf?id=';

            baseUrl += this.detailId;
            baseUrl += '&token=' + auth.getToken()
            baseUrl += '&name=' + name;
            baseUrl += '&_mini_app_id=' + auth.appId()

            baseUrl = encodeURI(baseUrl);

            return baseUrl
        }
    },
    mounted() {
        this.detailId = this.$route.params.id

        this.getDetail()
        this.getOptions()
    },
    methods: {
        openPdf() {
            this.$confirm({
                title: "pdf生成将消耗大量服务器资源，确定要生成吗？",
                content: "预计3-5分钟生成，请勿关闭新打开的页面",
                onOk: () => {
                    window.open(this.pdfUrl)
                }
            })
        },
        activeJobsModal() {

            this.jobs = this.detail.member_image.jobs

            this.showJobModal = true
        },
        syncImageInfo() {

            this.$loading.show()

            api.post('/ast-detect/sync-image', {
                jobs: this.jobs,
                id: this.detail.id
            }, res => {

                this.$loading.hide()

                if (res.code !== 0) {
                    return this.$message.data(res)
                }

                this.showJobModal = false

                this.detail.member_image.jobs = this.jobs

            })
        },
        getOptions() {
            api.get('/ast-detect/detect-options', {id: this.detailId}, (res) => {
                if (res.code === 0) this.detectOptions = res.data
            })
        },
        saveAuthorInfo(value) {
            this.saveExtraInfo(['author', value])
        },
        saveExtraInfo(params) {
            let [key, value] = params

            console.log(params);

            this.$loading.show()

            api.post('/ast-detect/save-extra', {
                key, value, id: this.detailId
            }, (res) => {

                this.$loading.hide()

                this.$message.data(res)

                if (res.code !== 0) return;

                if (this.detectOptions[key]) {

                    this.detectOptions[key]['cs'] = value

                } else {
                    this.getDetail()
                }

            })
        },
        getDetail() {
            this.$loading.show()

            api.get('/ast-detect/detail', {id: this.detailId}, (res) => {

                this.$loading.hide();


                if (res.code === 0) {

                    this.loadDetail(res.data)

                    return;
                }

                this.$message.data(res)

                setTimeout(() => {
                    this.$router.back();
                }, 1000)

            })
        },
        loadDetail(detail) {

            if (!detail.extra) detail.extra = {}

            this.detail = detail

        },
        editPhoto() {
            this.showHeadModal = true
        },
        headUploadDone(image) {

            this.$loading.show()

            api.post('/ast-detect/update-photo', {id: this.detail.id, image}, res => {

                this.$loading.hide()
                this.$message.data(res)

                if (res.code === 0) {
                    this.detail.photo = image
                    this.$forceUpdate()

                    this.showHeadModal = false
                }

            })

            return true;
        },
        jsGetAge(strBirthday) {

            if (!strBirthday) return ' - ';

            let returnAge;

            // 根据生日计算年龄

            //以下五行是为了获取出生年月日，如果是从身份证上获取需要稍微改变一下

            let strBirthdayArr = strBirthday.split("-");

            let birthYear = strBirthdayArr[0];

            let birthMonth = strBirthdayArr[1];

            let birthDay = strBirthdayArr[2];


            let d = new Date();

            let nowYear = d.getFullYear();

            let nowMonth = d.getMonth() + 1;

            let nowDay = d.getDate();


            if (nowYear === birthYear) {

                returnAge = 0;//同年 则为0岁

            } else {

                let ageDiff = nowYear - birthYear; //年之差

                if (ageDiff > 0) {

                    if (nowMonth === birthMonth) {

                        let dayDiff = nowDay - birthDay;//日之差

                        if (dayDiff < 0) {

                            returnAge = ageDiff - 1;

                        } else {

                            returnAge = ageDiff;

                        }

                    } else {

                        let monthDiff = nowMonth - birthMonth;//月之差

                        if (monthDiff < 0) {

                            returnAge = ageDiff - 1;

                        } else {

                            returnAge = ageDiff;

                        }

                    }

                } else {

                    returnAge = -1;//返回-1 表示出生日期输入错误 晚于今天

                }

            }

            return returnAge > 0 ? returnAge : 0;//返回周岁年龄
        }
    }
}
</script>

<style scoped lang="less">

.left-container {
    width: 380px;
}

.right-container {
    flex: 1;
}

.member-avatar {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    margin-right: 16px;
}

.containers {
    display: flex;
}

.member-info-table {
    width: 100%;
}

.detail-item {
    margin-bottom: 32px;
}

.np {
    margin-bottom: 0;
}

.p-image {
    width: 133px;
    height: 160px;
    left: 582px;
    top: 171px;
    background-size: cover;
    background-position: center;
}

.preview-pdf {
    padding-bottom: 36px;
}

.p-image-box {
    position: relative;

    .sc {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-color: rgba(0, 0, 0, 0.6);
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        cursor: pointer;
        opacity: 0;
        transition: all .3s;
        font-size: 26px;
    }

    &:hover {
        .sc {
            opacity: 1;
        }
    }
}


</style>