<template>
    <div>
        <span class="modal-editor" @click="showModal = true">
            <slot/>
        </span>


        <at-modal :title="title" v-model="showModal" @ok="save">
            <at-input :placeholder="placeholder"
                      v-model="inputValue"
                      class="full-with"
                      :unit="unit"
                      highlight></at-input>
        </at-modal>


    </div>
</template>

<script>
import AtInput from "@/components/AtInput.vue";
import AtModal from "@/components/AtModal.vue";

export default {
    name: "ModalEdit",
    components: {AtModal, AtInput},
    props: {
        title: {
            type: String,
            default() {
                return ''
            }
        },
        unit: String,
        value: [String, Number],
        placeholder: String,
    },
    data() {
        return {
            showModal: false,
            inputValue: "",
        }
    },
    mounted() {
        this.inputValue = this.value
    },
    watch: {
        value() {
            this.inputValue = this.value
        }
    },
    methods: {
        save() {
            this.$emit('save', this.inputValue, this);
            this.showModal = false
        },
    }
}
</script>

<style scoped lang="less">
.modal-editor {
    cursor: pointer;
    color: #1890ff;
}
</style>